import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import Resellers from "../components/resellers"
import FeatureColumn from "../components/feature-column"
import Testimonials from "../components/testimonials"

import resellers from "../data/resellers.json"

import { faPercent, faBullhorn, faStar } from "@fortawesome/free-solid-svg-icons"
import ActionForm from "../components/action-form"

const BecomeReseller = ({ data }) => (
  <Layout>
    <SEO
      title="Resell Celantur's Image and Video Anonymization Solutions"
      description="Grow your business by offering Celantur's image and video anonymization solutions to your customers."
    />
    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-80 bg-cover text-center"
      style={{
        background: "url(/images/earth-horizon.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1 className="display-2 font-weight-bold text-white">Become a Celantur Reseller</h1>
        <p className="lead font-weight-bold mb-6 mb-md-8 text-success">
          As a system integrator or software vendor, unlock new business opportunities with Celantur's cutting-edge
          image and video anonymization solutions.
        </p>
        <Link to="/contact/" className="btn btn-success lift mr-1 event-contact">
          Become a Reseller
        </Link>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <Resellers />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <h2 className="font-weight-bold">At Celantur, we believe in the power of partnerships.</h2>
            <p className="font-size-lg text-gray-700 mb-3">
              That's why we've created our Reselling Program specifically for vendors, manufacturers and system
              integrators.
            </p>
            <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
              By joining our program, you can offer your customers a state-of-the-art image and video anonymization
              solutions, empowering them to comply with data protection laws and meet growing privacy demands.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5 py-md-8">
      <div className="container">
        <div className="row">
          <FeatureColumn headline="Reseller Discount" icon={faPercent}>
            As a Celantur reseller, you gain access to exclusive discounts on our products and services. Boost your
            competitiveness and profitability while delivering unmatched value to your customers.
          </FeatureColumn>
          <FeatureColumn headline="Sales Training" icon={faStar}>
            We provide comprehensive sales training to help you effectively position and promote Celantur's solution.
            Equip your sales team with the knowledge and skills they need to successfully articulate the benefits of
            Celantur technology to your customers.
          </FeatureColumn>
          <FeatureColumn headline="Marketing Support" icon={faBullhorn}>
            Our dedicated marketing team is here to support you every step of the way. Leverage our marketing resources,
            including co-branded materials, case studies, and industry-specific content, to accelerate your sales cycle
            and drive customer engagement.
          </FeatureColumn>
        </div>
      </div>
    </section>

    <section
      className="py-12 py-md-15 bg-cover"
      style={{
        background: "url(/images/rgb-city-cars-bodies.png) no-repeat",
        backgroundSize: "",
        backgroundPositionY: "center",
        minWidth: "1000px",
      }}
    ></section>

    <Testimonials horus={true} allTerraDS={true} />

    <section className="py-8">
      <div className="container">
        <h2 className="font-weight-bold text-center">All resellers at a glance</h2>
        <div className="text-center">
          <Img fixed={data.resellerMap.childImageSharp.fixed} />
        </div>
        <div className="list-group">
          {resellers.map((item) => {
            return (
              <a href={item.link} className="list-group-item list-group-item-action" key={item.company} target="_blank">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">{item.company}</h5>
                  <small class="text-muted">{item.country}</small>
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </section>

    <section className="container my-5">
      <p className="font-size-lg text-muted mb-5 text-center">
        Ready to take your offerings to the next level?
        <br />
        Join the Celantur Reselling Program today and unlock new opportunities in the fast-growing privacy tech market.
        Reach out to us:
        <br />
      </p>
      <ActionForm
        headline="Become a Reseller."
        placeholderInput="A short summary of your company or question."
        labelSubmitButton="Send"
      />
    </section>

    <section className="py-8">
      <div className="container">
        <Customers />
      </div>
    </section>
  </Layout>
)

export default BecomeReseller

export const query = graphql`
  query {
    resellerMap: file(relativePath: { eq: "reseller-map-small.png" }) {
      childImageSharp {
        fixed(width: 720) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
